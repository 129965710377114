import sharpeLogoWhite from "../../images/sharpe-logo-white.svg";
import sharpeLogoBlack from "../../images/sharpe-logo-black.svg";

const footerNavigation = {
  products: [
    { name: 'Terminal', href: 'https://sharpe.ai/futures/Bitcoin/' },
    { name: 'Perpetual DEX', href: 'https://trade.sharpe.ai/' },
    { name: 'Bridge', href: 'https://bridge.sharpe.ai/' },
    { name: 'Launchpad', href: 'https://launchpad.sharpe.ai/' }
  ],
  community: [
    { name: 'Twitter', href: 'https://x.com/SharpeLabs' },
    { name: 'Telegram', href: 'https://t.me/SharpeAI_Official' },
    { name: 'DeBank', href: 'https://debank.com/official/Sharpe_AI/' },
    { name: 'Galxe', href: 'http://galxe.com/sharpelabs' }
  ],
  resources: [
    { name: 'Documentation', href: 'https://docs.sharpe.ai/' },
    { name: 'Blog', href: 'https://sharpeai.medium.com/' },
    { name: 'Whitepaper', href: 'https://github.com/Sharpelabs/Whitepaper' },
    { name: 'Governance', href: 'https://docs.sharpe.ai/introduction/governance' }
  ],
  company: [
    { name: 'Support', href: 'mailto:team@sharpe.ai' },
    { name: 'Security', href: 'https://github.com/Sharpelabs/Security' },
    {
      name: 'Media Kit',
      href: 'https://profuse-hurricane-790.notion.site/01edfa01ea9343a1aa96bb0d77132417?v=5e9cb96640244b2e921204ab112b82a1&pvs=74'
    }
  ],
  social: [
    {
      name: 'X',
      href: 'https://x.com/SharpeLabs',
      icon: props => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path d='M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z' />
        </svg>
      )
    },
    {
      name: 'Discord',
      href: 'https://discord.com/invite/tFAvMTw6Hx',
      icon: props => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path d='M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z' />
        </svg>
      )
    },
    {
      name: 'Telegram',
      href: 'https://t.me/SharpeAI_Official',
      icon: props => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path d='M11.944 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0  0 0 12 0a12 12 0 0 0-.056 0zm4.962 7.224c.1-.002.321.023.465.14a.506.506 0 0 1 .171.325c.016.093.036.306.02.472-.18 1.898-.962 6.502-1.36 8.627-.168.9-.499 1.201-.82 1.23-.696.065-1.225-.46-1.9-.902-1.056-.693-1.653-1.124-2.678-1.8-1.185-.78-.417-1.21.258-1.91.177-.184  3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793  1.14-5.061 3.345-.48.33-.913.49-1.302.48-.428-.008-1.252-.241-1.865-.44-.752-.245-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.83-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635z' />
        </svg>
      )
    }
  ]
}

export default function FooterSection({ mode }) {
  const isDarkMode = mode !== "light";

  const bgColor = isDarkMode ? "bg-[#000]" : "bg-white";
  const textColor = isDarkMode ? "text-gray-300" : "text-gray-600";
  const headingColor = isDarkMode ? "text-white" : "text-gray-900";
  const hoverColor = isDarkMode ? "hover:text-white" : "hover:text-gray-900";
  const iconColor = isDarkMode
    ? "text-gray-500 hover:text-gray-400"
    : "text-gray-400 hover:text-gray-500";

  return (
    <footer aria-labelledby='footer-heading' className='bg-[#131313]'>
    <h2 id='footer-heading' className='sr-only'>
      Footer
    </h2>
    <div className='mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32'>
      <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
        <div className='space-y-8'>
          <img src={sharpeLogoWhite} alt='Sharpe Logo' style={{ marginLeft: '-6px' }} height={30} width={140} />
          <p className='text-sm leading-6 text-gray-300'>
            Sharpe AI is the Frontend for Intelligence, Tracking and Investing your Digital Assets.
          </p>
        </div>
        <div className='mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0'>
          <div className='md:grid md:grid-cols-2 md:gap-8'>
            <div>
              <h3 className='text-sm font-semibold leading-6 text-white'>Products</h3>
              <ul role='list' className='mt-6 space-y-4'>
                {footerNavigation.products.map(item => (
                  <li key={item.name}>
                    <a href={item.href} className='text-sm leading-6 text-gray-300 hover:text-white'>
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className='mt-10 md:mt-0'>
              <h3 className='text-sm font-semibold leading-6 text-white'>Community</h3>
              <ul role='list' className='mt-6 space-y-4'>
                {footerNavigation.community.map(item => (
                  <li key={item.name}>
                    <a href={item.href} className='text-sm leading-6 text-gray-300 hover:text-white'>
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='md:grid md:grid-cols-2 md:gap-8'>
            <div>
              <h3 className='text-sm font-semibold leading-6 text-white'>Resources</h3>
              <ul role='list' className='mt-6 space-y-4'>
                {footerNavigation.resources.map(item => (
                  <li key={item.name}>
                    <a href={item.href} className='text-sm leading-6 text-gray-300 hover:text-white'>
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className='mt-10 md:mt-0'>
              <h3 className='text-sm font-semibold leading-6 text-white'>Company</h3>
              <ul role='list' className='mt-6 space-y-4'>
                {footerNavigation.company.map(item => (
                  <li key={item.name}>
                      <a href={item.href} className='text-sm leading-6 text-gray-300 hover:text-white'>
                        {item.name}
                      </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24'>
        <p className='text-xs leading-5 text-gray-400'>&copy; 2024 Sharpe Labs Ltd. All rights reserved.</p>
      </div>
      <div className='mt-4'>
        <p className='text-[8px] leading-4 text-gray-400'>
          This content is for informational purposes only and is not legal, tax, investment, financial, or other
          advice. You should not take, or refrain from taking, any action based on any information contained herein,
          or any other information that we make available at any time, including blog posts, data, articles, links to
          third-party content, discord content, news feeds, tutorials, tweets, and videos. Before you make any
          financial, legal, technical, or other decisions, you should seek independent professional advice from a
          licensed and qualified individual in the area for which such advice would be appropriate. This information
          is not intended to be comprehensive or address all aspects of Sharpe Labs Ltd or its products. You shall not
          purchase or otherwise acquire any of our token products if you are: a citizen, resident (tax or otherwise),
          green card holder, incorporated in, owned or controlled by a person or entity in, located in, or have a
          registered office or principal place of business in the U.S. (a "U.S. Person"), or if you are a person in
          any jurisdiction in which such offer, sale, and/or purchase of any of our token products is unlawful,
          prohibited, or unauthorized (together with U.S. Person, a "Restricted Person"). The term "Restricted Person"
          includes, but is not limited to, any natural person residing in, or any firm, company, partnership, trust,
          corporation, entity, government, state or agency of a state, or any other incorporated or unincorporated
          body or association, association or partnership (whether or not having separate legal personality) that is
          established and/or lawfully existing under the laws of, a jurisdiction in which such offer, sale, and/or
          purchase of any of our token products is unlawful, prohibited, or unauthorized). You shall not resell or
          otherwise transfer any of our token products to any Restricted Person. The transfer or resale of any of our
          token products to any Restricted Person is not permitted. You shall read the Terms of Service and use our
          Website in compliance with the Terms of Service.
        </p>
      </div>
    </div>
  </footer>
  );
}
